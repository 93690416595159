<template>
  <div class="query-search">
    <div class="trouble-top">
      <template v-if="+activeName !== 1">
        <van-search
          v-model="query.name"
          class="search-bar"
          placeholder="请输入隐患名称或发现人关键字"
          @clear="onConfirm"
          @search="onConfirm"
        >
          <template #left-icon>
            <van-icon
              name="sousuo"
              class-prefix="iconfont"
              class="search-icon"
            />
          </template>
        </van-search>
        <date-time v-model="dateTime" :time-close="false" type="date" />
        <van-field
          v-model="query.discoverDeptName"
          left-icon="shouqi"
          readonly
          icon-prefix="iconfont"
          placeholder="发现人部门"
          class="select-department"
          @click="departmentVisible = true"
        />
        <van-field
          v-model="query.discoveryName"
          left-icon="shouqi"
          readonly
          icon-prefix="iconfont"
          placeholder="发现方式"
          class="select-department"
          @click="
            onSearchClick({
              title: '发现方式',
              type: 'discoveryId',
              types: 'findMode'
            })
          "
        />
        <van-field
          v-model="query.troubleSourceName"
          left-icon="shouqi"
          readonly
          icon-prefix="iconfont"
          placeholder="隐患来源"
          class="select-department"
          @click="
            onSearchClick({
              title: '隐患来源',
              type: 'troubleSource',
              types: 'troubleSourceList'
            })
          "
        />
      </template>
      <div class="search-query">
        <fix-list-query
          v-if="+activeName !== 1"
          query="query"
          @refresh="onQueryChange"
        />
        <div class="trouble-total">共{{ total }}处隐患</div>
      </div>
    </div>
    <van-pull-refresh
      ref="trouble-list"
      v-model="refreshing"
      class="trouble-listBlock"
      :style="{ height }"
      @refresh="onRefresh"
    >
      <van-list
        v-model="loading"
        :error.sync="isError"
        :finished="finished"
        :finished-text="isError ? '' : '没有更多了'"
        error-text="加载失败，请稍后再试！"
        @load="onLoad"
      >
        <div
          v-for="(item, idx) in troubleList"
          :key="idx"
          class="trouble-item"
          @click="gotoDetails(item)"
        >
          <div class="trouble-header">
            <div class="trouble-title">
              <div class="trouble-title-text">
                {{ item.troubleLevelName }} - {{ item.name }}
              </div>
              <div class="trouble-level">{{ item.discoverDate }}</div>
            </div>
            <div
              :style="{ color: troubleStatusColor(item.disposeStatus) }"
              class="trouble-status"
            >
              <van-icon
                :name="troubleStatusName(item.disposeStatus)"
                class-prefix="iconfont"
                class="trouble-status-icon"
              />
            </div>
          </div>
          <div class="trouble-body">
            <div class="org-equipment">
              <van-icon
                class-prefix="iconfont"
                class="org-equipment-icon"
                name="jigou"
              />
              {{ item.departmentName || "--" }} |
              <span v-if="item.poiName">{{ item.poiName }} |</span>
              <van-icon
                class-prefix="iconfont"
                class="org-equipment-icon"
                name="icomember"
              />
              {{ item.discovererName || "--" }}
            </div>
            <div class="trouble-time">
              {{ item.discoveryName }} | {{ item.categoryName }}
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <cascade-department
      v-model="department"
      title="发现人部门"
      :visible="departmentVisible"
      include-children
      @close="departmentVisible = false"
      @confirm="onDeptChange"
      @reset="onReset"
    />
    <select-popup
      :choose-value="popupValue"
      :show-filter="false"
      :data="popupData"
      title="发现方式"
      :visible="popupVisible"
      :popup-style="{
        height: '50vh'
      }"
      @close="popupVisible = false"
      @confirm="onPopupConfirm"
    />
  </div>
</template>

<script>
import {
  getSelectFindMode,
  getSelectTroubleSource,
  getSelectTroubleStatus,
  getTroubleList
} from "@/api/psm/trouble";
import { TROUBLE_ICON_STATUS } from "../../utils/constant";
import getSelectMixin from "@/views/trouble/mixin/getSelectMixin";
import FixListQuery from "@/views/trouble/trouble-fix/components/FixListQuery";
import { mapState } from "vuex";
import SelectPopup from "@/components/SelectPopup";

export default {
  props: {
    activeName: [String, Number]
  },
  components: { SelectPopup, FixListQuery },
  mixins: [getSelectMixin],
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    }),
    troubleStatusName() {
      return function(status) {
        status = String(status);
        if (status === "null" || !status) return "";
        return TROUBLE_ICON_STATUS[status]["iconName"];
      };
    },
    troubleStatusColor() {
      return function(status) {
        status = String(status);
        if (status === "null" || !status) return "";
        return TROUBLE_ICON_STATUS[status]["iconColor"];
      };
    }
  },
  data() {
    return {
      department: "",
      departmentVisible: false,
      dateTime: {
        start: "",
        end: ""
      },
      total: 0,
      radio: "",
      troubleStatus: [],
      // 隐患查询参数
      query: {
        page: 1,
        size: 10,
        name: "",
        troubleLevel: "",
        categoryId: "",
        stage: "", //隐患阶段
        planId: "", //隐患排查计划id
        disposeStatus: "", //计划状态
        startDate: "",
        endDate: "",
        orgCode: "",
        department: "",
        subDepartment: true,
        queryType: "0",
        discoverDeptName: "",
        discoverDeptId: "",
        discoveryId: "",
        discoveryName: "",
        troubleSource: "",
        troubleSourceName: ""
      },
      // 下拉刷新，上拉加载
      loading: false,
      finished: false,
      refreshing: false,
      isError: false,
      // 隐患列表
      troubleList: [],
      findMode: [],
      troubleSourceList: [],
      scrollTop: 0,
      height: "auto",
      popupVisible: false,
      popupTitle: "",
      popupData: [],
      popupValue: ""
    };
  },
  watch: {
    activeName: {
      handler(val) {
        this.computedHeight();
        if (!val) return;
        this.query.queryType = val ?? "0";
        this.onConfirm();
      },
      immediate: true
    },
    dateTime: {
      handler(val) {
        if (val.start && val.end) {
          this.query.startDate = val.start + " 00:00:00";
          this.query.endDate = val.end + " 23:59:59";
          this.onConfirm();
        }
        if (!val.start && !val.end) {
          this.query.startDate = "";
          this.query.endDate = "";
          this.onConfirm();
        }
      },
      deep: true
      // immediate: true
    }
  },
  created() {
    this.initTypes();
    this.query.orgCode = this.userInfo.orgCode;
  },
  activated() {
    this.computedHeight();
    this.setPageScrollTop();
    this.onConfirm();
  },
  methods: {
    onPopupConfirm(row) {
      console.log(row, "row");
      const map = {
        discoveryId: "discoveryName",
        troubleSource: "troubleSourceName"
      };
      this.query[this.popupType] = row.value ?? "";
      this.query[map[this.popupType]] = row.label ?? "";
      this.onConfirm();
    },
    onSearchClick({ title, type, types }) {
      this.popupValue = this.query[type] || "";
      this.popupType = type;
      this.popupData = this[types];
      this.popupTitle = title;
      this.popupVisible = true;
    },
    onReset() {
      this.query.discoverDeptId = this.department;
      this.onConfirm();
    },
    onDeptChange(selectValue, includeInfo) {
      if (includeInfo?.include) {
        this.query.discoverDeptId = selectValue.id;
      } else {
        this.query.discoverDeptId = this.department;
      }
      this.query.subDepartment = includeInfo?.include;
      this.query.discoverDeptName = selectValue.label;
      this.onConfirm();
    },
    async setPageScrollTop() {
      await this.$nextTick();
      const troubleListCom =
        this.$refs["trouble-list"].$el || this.$refs["trouble-list"];
      troubleListCom.scrollTo(0, this.scrollTop);
    },
    async computedHeight() {
      await this.$nextTick();
      const troubleListCom =
        this.$refs["trouble-list"].$el || this.$refs["trouble-list"];
      const { top } = troubleListCom.getBoundingClientRect();
      this.height = `calc(100vh - ${top}px)`;
    },
    async gotoDetails(row) {
      await this.$nextTick();
      this.scrollTop = this.$refs["trouble-list"].$el.scrollTop;
      await this.$router.push({ path: "/trouble/" + row.id });
    },
    onQueryChange(query) {
      this.query = { ...this.query, ...query };
      this.onConfirm();
    },
    async initTypes() {
      try {
        const rets = await Promise.all([
          getSelectTroubleStatus(),
          getSelectFindMode(),
          getSelectTroubleSource()
        ]);
        this.troubleStatus = rets[0];
        this.findMode = rets[1];
        this.troubleSourceList = rets[2];
      } catch (error) {
        console.log("initTypes -> error", error);
      }
    },
    onCancel() {
      this.query.troubleLevel = "";
      this.query.disposeStatus = "";
      this.query.categoryId = "";
    },
    onConfirm() {
      this.query.page = 1;
      this.troubleList = [];
      this.onLoad();
    },
    async onLoad() {
      this.finished = false;
      this.loading = true;
      this.isError = false;
      if (this.refreshing) {
        this.troubleList = [];
        this.refreshing = false;
      }
      try {
        const { total, list } = await getTroubleList(this.query);
        this.loading = false;
        this.total = total;
        // 当前列表数量等于总数 暂无内容
        if (list && list.length) {
          this.troubleList = this.troubleList.concat(list);
          // 加载成功下次页码加1
          this.query.page++;
        } else {
          this.finished = true;
        }
      } catch (error) {
        console.log("onLoad -> error", error);
        this.loading = false;
        this.finished = true;
        this.isError = true;
      }
    },
    onRefresh() {
      // 清空列表数据
      this.query.page = 1;
      this.onLoad();
    }
  }
};
</script>

<style lang="scss">
.query-search {
  .select-department.van-cell {
    width: inherit;
    margin: 12px 16px;
    border: 0.5px solid #e1e3e8;
    border-radius: 2px;
    padding-top: 8px;
    padding-left: 12px;
    padding-bottom: 7px;
    font-size: 14px;
    line-height: 1;

    .van-field__value {
      line-height: 20px;
    }

    ::-webkit-input-placeholder {
      color: #2e2e4d;
    }

    :-moz-placeholder {
      /* Firefox 18- */
      color: #2e2e4d;
    }

    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #2e2e4d;
    }

    :-ms-input-placeholder {
      color: #2e2e4d;
    }

    .van-field__left-icon {
      line-height: 20px;

      .iconfont {
        color: #aeb3c0;
        font-size: 12px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.query-search ::v-deep .filter-popup {
  .van-radio {
    margin: 2vw 0 2vw 5vw;
    width: 35vw;
  }
  .van-radio:nth-child(2n) {
    width: 39vw;
  }
}

.last-screening {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  font-size: 12px;
}
.van-dropdown-menu {
  background-color: transparent;
  height: inherit;
}
.screening-btns {
  font-size: 12px;
  margin: 15px 0;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}
.screening-item {
  padding: 0 20px;
  h4 {
    text-align: left;
    margin: 10px 0;
    margin-top: 15px;
  }
  .screening-radio {
    padding-left: 15px;
  }
}
.query-search {
  margin: 0;
  display: flex;
  height: 100%;
  flex-direction: column;
  .trouble-total {
    text-align: center;
    color: #8c8f97;
    font-size: 14px;
    margin: 14px 0;
  }
}
.trouble-top {
  /*height: 188px;*/
  height: auto;
  .search-bar {
    padding-top: 16px;
    padding-bottom: 12px;
    margin-bottom: -12px;
  }
}
.trouble-listBlock {
  overflow-y: auto;
}
.trouble-item:nth-of-type(1) {
  padding-top: 0;
}
.trouble-item:not(:last-child) {
  border-bottom: 8px solid #eceef2;
}
.trouble-item {
  padding: 5.6vw 5.6vw 2vh;
  font-size: 4vw;
  .trouble-header {
    display: flex;
    padding-top: 3px;
    .trouble-title {
      flex: 3;
      max-width: 70vw;
      color: #3b4664;
      .trouble-level {
        color: #aeb3c0;
        font-size: 13px;
        margin: 7px 0;
        margin-bottom: 4px;
      }
      .trouble-title-text {
        line-height: 22px;
        width: 100%;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box; //作为弹性伸缩盒子模型显示。
        -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
        -webkit-line-clamp: 2; //显示的行
      }
    }
    .trouble-status {
      flex: 1;
      color: #c72391;
      text-align: right;
      // i {
      //   font-size: 32px;
      // }
    }
    .trouble-status-icon {
      font-size: 40px;
    }
  }
  .trouble-body {
    font-size: 13px;
    color: #aeb3c0;
    .org-equipment {
      margin-bottom: 0.6vh;
    }
    .org-equipment-icon {
      font-size: 13px;
    }
  }
}
</style>
